@import "react-slideshow-image/dist/styles.css";

html{
  scroll-behavior: smooth;
}

.App {
  .white {
    color: white;
  }

  .loader-progress {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    margin-top: -10px;
    z-index: 999;
    .loader {
      height: 10px;
      background: #f5b71b;
    }
  }

  .header-landing {
    padding: 40px 120px 40px 120px;
    box-sizing: border-box;
    margin-top: -20px;
    z-index: 999;
    position: absolute;
    top: 0;

    .header-wrapper {
      max-width: 1243px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .landing-sells {
    .hero-banner {
      @media screen and (max-width: 639px) {
        background: linear-gradient(#fbb714, #da8e08);
      }
    }

    .hero-right-bg {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    .bg-video-yellow {
      width: 100%;
      max-width: 720px;
      transform: rotate(-8deg);
      height: 400px;
      background-color: #ffce3340;
      border-radius: 18px;
      z-index: -1;
      position: absolute;
      margin-top: -12px;

      @media screen and (max-width: 820px) {
        height: 380px;
        max-width: 700px;
      }

      @media screen and (max-width: 768px) {
        height: 320px;
        max-width: 700px;
      }

      @media screen and (max-width: 639px) {
        height: 320px;
        max-width: 540px;
      }

      @media screen and (max-width: 540px) {
        height: 280px;
        max-width: 440px;
      }

      @media screen and (max-width: 435px) {
        height: 180px;
        max-width: 280px;
      }
    }

    .assessment-section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      padding: 60px 0;
      background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676249339/ukodesh-media/Group_112_xjirzi.svg");
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 1024px) {
        background-size: 300px;
      }

      @media screen and (max-width: 639px) {
        background-size: 200px;
        background-position: top left;
      }

      .assessment-wrapper {
        display: flex;
        padding: 40px 0;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        .left-side {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .img-assessment {
            width: 320px;
          }
        }

        .right-side {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex-direction: column;

          .inner-text {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            max-width: 400px;
            text-align: center;

            @media screen and (max-width: 1024px) {
              margin-top: 12px;
            }

            @media screen and (max-width: 639px) {
              text-align: center;
            }
          }

          .card-form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 40px;
            border-radius: 24px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-top: 40px;
            margin-bottom: 12px;
            width: 260px;
            background: #0075ff;

            @media screen and (max-width: 639px) {
              width: 200px;
            }

            select {
              background: #ffffff52;
              border: none;
              border-radius: 4px;
              height: 34px;
              outline: none;
              padding: 4px 4px 4px 10px;
              margin-bottom: 12px;
              color: white;
              width: 100%;
            }
          }
        }
      }

      .inner-text {
        text-align: center;
      }
    }

    .features-section {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      padding: 60px 0;
      background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676248239/ukodesh-media/Group_114_wvvudy.svg");
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 1024px) {
        background-size: 300px;
      }

      @media screen and (max-width: 430px) {
        background-size: 200px;
        background-position: center right;
      }

      .landing-wrapper {
        @media screen and (max-width: 1024px) {
          max-width: 600px;
          text-align: center;
        }

        @media screen and (max-width: 768px) {
          max-width: 250px;
          text-align: center;
        }
      }
    }

    .students-section {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      padding: 60px 0;
      background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676066643/ukodesh-media/Vector_nvuwqr.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 1100px;

      .landing-wrapper {
        max-width: 1356px;

        @media screen and (max-width: 1280px) {
          max-width: 1048px;
        }

        @media screen and (max-width: 430px) {
          text-align: center;
        }
      }
    }

    .students-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      box-sizing: border-box;
      width: 100%;
      padding: 60px 0;

      @media screen and (max-width: 1024px) {
        padding: 60px;
        grid-template-columns: 1fr;
      }

      @media screen and (max-width: 430px) {
        padding: 0;
      }
    }
  }

  .gradient-title {
    background: linear-gradient(#562ff4, #43e8b6);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  .white-font {
    color: white;
  }

  .f-24 {
    font-size: 20px;
  }

  .f-10 {
    font-size: 10px;
  }

  .f-12 {
    font-size: 12px;
  }

  .f-18 {
    font-size: 18px;
  }

  .red-text {
    color: rgb(179, 26, 26);
  }

  .grey-text {
    color: rgb(114, 114, 114);
  }

  .mt-2 {
    margin-top: 20px;
  }

  .yellow-text {
    color: #ec9f0a;
  }

  .submit-btn {
    border: none;
    width: 100%;
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #0075ff;
    background: white;
    cursor: pointer;
  }

  .landing-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1080px;
    width: 100%;
    padding: 0 48px;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .secondary-color {
    color: #8191ab;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .title {
    font-size: 32px;

    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }

    @media screen and (max-width: 430px) {
      font-size: 18px;
    }
  }

  .logo {
    width: 96px;
    filter: invert(1);
  }

  .hero-video {
    background: #fbb71470;
  }
}

.bg-nowait {
  position: absolute;
  width: 100%;
  z-index: -1;
  max-height: 1200px;
}

.bg-nowait-bg {
  background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_28_gzngn5.png");
  background-position: top;
  width: 100%;
  z-index: -1;
  max-height: 1200px;
}

.bg-reviews {
  position: absolute;
  width: 100%;
  margin-top: 48px;
  height: 100%;
  @media screen and (max-width: 820px) {
    z-index: -1;
  }
  @media screen and (max-width: 435px) {
    display: none;
  }
}

.bg-plans {
  width: 100%;
  z-index: -1;
  background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_29_zlhykd.png");
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.accordion {
  border-bottom: 1px solid rgb(103, 103, 103);
  margin-bottom: 24px;
  width: 100%;
  .accordion-header {
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    margin: 8px 0;
    color: #2e3035;

    .accordion-icon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 8px;
      span {
        width: 16px;
        height: 4px;
        transition: 0.1s ease-in-out;
        background: #2e3035;
      }
    }
  }

  .accordion-content {
    overflow: hidden;
    margin-bottom: 12px;
    span {
      width: 100%;
      margin: 8px 0;
      font-size: 0.875rem;
      font-weight: 300;
      color: #2e3035;
      display: block;
      font-weight: 300;
    }
  }
}

.accordion:hover {
  cursor: pointer;
}

.react-slideshow-container .default-nav {
  background: none;
}
